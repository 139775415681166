export const GoogleMapKey = "AIzaSyBRDiz2nDH62cem9lKjnba7bURqczabdd4";
export const WeatherKey = 'd5afe18ec1684275ab201ce253640f5b'

export const firebaseConfig = {
  apiKey: "AIzaSyAUZw_a_6vLzHArHfSeyEfGhcVLldIbm6Y",
  authDomain: "cheezoo-prod.firebaseapp.com",
  projectId: "cheezoo-prod",
  storageBucket: "cheezoo-prod.appspot.com",
  messagingSenderId: "866148229081",
  appId: "1:866148229081:web:ef2ea31b9bae0e87c043c8",
  measurementId: "G-N56C389WM9"
};

export const firebaseVapidKey = 'BKHPikf1veWLpWV48CKaJM36C5RjCQKYdRh3D-rFSNLeCYCD3JnsHUjIdIxRTjxC3t8qjKrbXbyEyJPnfCaSifU';
