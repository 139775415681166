import { createGlobalState } from 'react-hooks-global-state';
import { getSectionFromUrl } from './common/UtilitiyFunctions';

const sections = {
   profile: "profile",
   portfolio: "portfolio",
   scheduling: "schedule",
   packages: "packages",
   membership: "membership",
   orderHistory: "order-history",
   transactions: "transactions",
   notifications: "notifications",
   changePassword: "change-password",
   helpdesk: "helpdesk",
   privacyPolicy: "privacy-policy",
   termsAndConditions: "terms-and-conditions",
   logout: "logout",
   consent: "consent",
 };

const initialState = {
   rData:rData ? rData : {},
   currentSection : getSectionFromUrl(sections, sections.profile),
   allLoaded:false,
   child3Loading:true,
   child4Loading:true,
   child1Loading:true,
   child2Loading:true,
   profile_pic:rData?.current_user_profile_pic,
   full_name:rData?.current_user_name ?? ''
};

export const { useGlobalState, setGlobalState } = createGlobalState(initialState);



