import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import {
  getAuth,
  setPersistence,
  indexedDBLocalPersistence,
  browserLocalPersistence,
  updateProfile,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  sendEmailVerification,
  GoogleAuthProvider,
  FacebookAuthProvider,
  EmailAuthProvider,
  signInWithPopup,
  signOut,
  sendPasswordResetEmail,
  updatePassword,
  updateEmail,
  deleteUser,
  reauthenticateWithCredential,
  reauthenticateWithPopup,
  reauthenticateWithPhoneNumber,  
  fetchSignInMethodsForEmail,
  applyActionCode, checkActionCode
} from "firebase/auth";
import { AppService } from "./AppService";
import { firebaseConfig, firebaseVapidKey } from "../common/keys";
import { getMessaging, getToken } from "firebase/messaging";
import showToast from "../common/toastify";
// import moment from 'moment';



// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = getAuth(app);
// setPersistence(auth, browserLocalPersistence);
const messaging = getMessaging(app);

const subscribeAuthChange = async () => {
  auth.onAuthStateChanged(async (firebaseUser) => {
    console.log('on state change');
    if (!firebaseUser) {
      return;
    }
    let idToken = '';
    if (firebaseUser.idToken) {
      idToken = firebaseUser.idToken;
    } else {
      idToken = await firebaseUser.getIdToken();
    }
    if (idToken) {
      AppService.doServerLogin(idToken);
    }
  })
}

const deleteFirebaseUser = async (u, toReturn) => {
  let deleted = false,errorCode = '';
  if (!auth.currentUser) {
    throw new Error("firebase authenticated user not present");
  }
  const user = auth.currentUser;
  try {
    await deleteUser(user);
    deleted = true
    console.log("User email deleted");
  } catch (error) {
    errorCode = error.code;
    const errorMessage = error.message;
    if (errorCode === "auth/requires-recent-login") {
      deleted = 'requires-login'
      // showToast("To delete your account securely, please log out and then log back in with your current account details", "error");
    } else if (errorCode === "auth/user-not-found") {
      // showToast("You are not registered with us.", "error");
    } else {
      // showToast("Something went wrong!", "error");
    }
  }
  if (toReturn) return deleted;
}

const registerUsingEmailPssword = async (email, password, name = "") => {
 try{
  let registered = true;
  const credential = await createUserWithEmailAndPassword(
    auth,
    email,
    password
  )
  if(!credential?.user){
   return {
    registered:false
  }
  }
  console.log("User created in firebase", credential);
  const firebaseUser = credential?.user;
  if(firebaseUser)
  await updateProfile(firebaseUser, {
    displayName: name
  })
  return {
    firebaseUser,
    credential,
    registered
  } 
}catch (error) {
    const errorCode = error.code;
    return {
      firebaseUser:null,
      credential:null,
      registered:false,
      code:errorCode
    } 
  }
};

const signInUsingGoogle = async () => {
  const provider = new GoogleAuthProvider();
  const result = await signInWithPopup(auth, provider);
  const firebaseUser = result.user;
  const credential = GoogleAuthProvider.credentialFromResult(result);
  console.log("User singed in in firebase using google", result, credential);
  return {
    firebaseUser,
    credential
  };
};

const signInUsingFacebook = async () => {
  const provider = new FacebookAuthProvider();
  // provider.addScope("user_birthday");
  const result = await signInWithPopup(auth, provider);
  const firebaseUser = result.user;
  const credential = FacebookAuthProvider.credentialFromResult(result);
  // const accessToken = credential.accessToken;
  // const response = await fetch(`https://graph.facebook.com/me?access_token=${accessToken}&fields=birthday`,{
  //   method:'GET'
  // });    
  // if(response){
  //   const data = await response?.json();
  //   const dateOfBirth = data?.birthday;
  //   console.log("Date of Birth:", dateOfBirth);
  //   firebaseUser['dob'] = dateOfBirth ? moment(dateOfBirth).format('YYYY-MM-DD') : '';
  // }
  console.log("User singed in in firebase using facebook", result, credential);
  return {
    firebaseUser,
    credential
  };
};

const loginUsingEmailPassword = async (email, password) => {
  const credential = await signInWithEmailAndPassword(
    auth,
    email,
    password
  );
  const firebaseUser = credential.user;
  console.log("User loggedin in firebase with email password", credential);
  return {
    firebaseUser,
    credential
  };
};


const logoutUser = async () => {
  await signOut(auth);
}

const updateUserDetails = async () => { };

const verifyEmail = async (errorShown=true) => {
  let errorCode = '',sent = false;;
  if (!auth.currentUser) {
    throw new Error("firebase authenticated user not present");
  }
  let admin = rData?.user_roles?.includes('admin') || false
  try {
    let response = await fetch('/generateEmailVerificationLink?email=' + encodeURIComponent(auth.currentUser?.email) + '&mode=' + encodeURIComponent('VERIFY') + '&displayName=' + encodeURIComponent(auth.currentUser?.displayName) + '&hostWebstite=' + encodeURIComponent(window.location.origin) + '&admin=' + admin)
    const data = await response?.json();
    console.log('data',data);
    if(!response.ok){
      throw data?.error;
    } else{
      sent = true;
    }
  } catch (error) {
    errorCode = error.code;
    const errorMessage = error.message;
    if (errorCode === "auth/user-not-found") {
      showToast("You are not registered with us", "error");
    } else if (errorCode === "auth/internal-error" && errorShown === true) {
      showToast('Oops! Too many tries. Please try again later!')
    } else if(!errorCode) {
      showToast("Something went wrong!", "error");
    }     
  }
  return {sent}
};

const requestPasswordReset = async (email) => {
  const data = await sendPasswordResetEmail(auth, email, {
    url: `${window.location.origin}/get-started`
  });
  console.log("Reset email sent");
}

const updateFirebaseUserPassword = async (password) => {
  if (!auth.currentUser) {
    throw new Error("firebase authenticated user not present");
  }
  await updatePassword(auth.currentUser, password);
  console.log("User password updated");
};


const updateFirebaseUserEmail = async (email) => {
  let updated = false, errorCode = '';
  if (!auth.currentUser) {
    throw new Error("firebase authenticated user not present");
  }
  // let admin = rData?.user_role?.includes('admin') || false
  // await fetch('/generateEmailVerificationLink?email=' + encodeURIComponent(email) + '&mode=' + encodeURIComponent('UPDATE') + '&displayName=' + encodeURIComponent(auth.currentUser?.displayName) + '&hostWebstite=' + encodeURIComponent(window.location.origin) + '&uid=' + auth.currentUser?.uid + '&admin=' + admin)
  try {
    await updateEmail(auth.currentUser, email);
    let admin = rData?.user_roles?.includes('admin') || false
    await fetch('/generateEmailVerificationLink?email=' + encodeURIComponent(email) + '&mode=' + encodeURIComponent('VERIFY') + '&displayName=' + encodeURIComponent(auth?.currentUser?.displayName) + '&hostWebstite=' + encodeURIComponent(window.location.origin) + '&admin=' + admin)
    updated = true
    console.log("User email updated");
  } catch (error) {
    errorCode = error.code;
    const errorMessage = error.message;
    if (errorCode === "auth/requires-recent-login") {
      // showToast("To update your account info securely, please log out and then log back in with your current account details", "error");
    } else if (errorCode === "auth/user-not-found") {
      showToast("You are not registered with us", "error");
    } else {
      showToast("Something went wrong!", "error");
    }
  }
  return {updated , errorCode};
};

const getMessagingToken = async () => {
  let token = '';
  try {
    token = await getToken(messaging, {
      vapidKey: firebaseVapidKey
    })
    if (!token) {
      const permission = await Notification.requestPermission();
      if (permission == 'granted') {
        token = await getToken(messaging, {
          vapidKey: firebaseVapidKey
        })
      }
    }
    token = token || '';
  } catch (error) {
    console.log(error);
  }
  return token;

}

async function handleVerifyEmail(auth, actionCode, continueUrl, lang = 'en') {
  let isVerified = false,os ='';
  try {
    const info = await checkActionCode(auth, actionCode); // check action code
    console.log(info)
    const res = await applyActionCode(auth, actionCode); // apply action code
    isVerified = true;
    const response = await fetch(`/send-welcome-email`, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ email: info.data.email })
    })
    console.log('Email verified successfully!');
    const data = await response.json();
    console.log(data);
    os = data?.os;
    
  } catch (error) {
    const errorCode = error.code;
    const errorMessage = error.message;
    if (errorCode === "auth/invalid-email") {
      showToast("Invalid Email Address", "error");
      setTimeout(() => {
        window.location.href = 'https://app.cheezoo.com/login'
      }, 1000);
    } else if (errorCode === "auth/user-not-found") {
      showToast("User not found", "error");
      setTimeout(() => {
        window.location.href = 'https://app.cheezoo.com/login'
      }, 1000);
    } else if (errorCode === "auth/expired-action-code") {
      showToast("Link has expired", "error");
      setTimeout(() => {
        window.location.href = 'https://app.cheezoo.com/login'
      }, 1000);
    } else if (errorCode === "auth/invalid-action-code") {
      showToast("Link is invalid", "error");
      setTimeout(() => {
        window.location.href = 'https://app.cheezoo.com/login'
      }, 1000);
    } else {
      showToast("Something went wrong!", "error");
      setTimeout(() => {
        window.location.href = 'https://app.cheezoo.com/login'
      }, 1000);
    }
  }
  return {
    isVerified,
    os
  }
}



export const FirebaseService = {
  auth,
  registerUsingEmailPssword,
  signInUsingGoogle,
  signInUsingFacebook,
  loginUsingEmailPassword,
  updateUserDetails,
  verifyEmail,
  logoutUser,
  subscribeAuthChange,
  requestPasswordReset,
  updateFirebaseUserPassword,
  updateFirebaseUserEmail,
  deleteFirebaseUser,
  getMessagingToken,
  handleVerifyEmail,
  signInWithPopup,
  reauthenticateWithCredential,
  reauthenticateWithPopup,
  reauthenticateWithPhoneNumber, 
  fetchSignInMethodsForEmail,
  EmailAuthProvider,
  FacebookAuthProvider,
  GoogleAuthProvider
};
