import { Modal } from "@mantine/core";
import React, { useState, useEffect } from "react";
import { StartSpinner, StopSpinner } from "../../common/Spinner/spinner";
import showToast from "../../common/toastify";

const ConsentActionComponent = () => {
  const [consents, setConsents] = useState([]);
  const [currentConsent, setCurrentConsent] = useState(null);
  const [dataStatus, setDataStatus] = useState("idle")

  const getConsents = async () => {
    StartSpinner();
    try {
      const response = await fetch("/album_image_consent.json");
      const consents = await response.json();
      setDataStatus("loaded")
      setConsents(consents);
    } catch (e) {
      console.log(e);
      showToast("Unable to fetch consents.", "error");
    }
    StopSpinner();
  };

  const updateConsent = async (consent, action) => {
    StartSpinner();
    try {
      let url;
      let status;
      if (action == "approve") {
        url = `/album_image_consent/${consent.id}.json`;
        status = "accepted";
      } else {
        url = `/album_image_consent/${consent.id}.json`;
        status = "rejected";
      }

      const resp = await fetch(url, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          status,
        }),
      });
      const data = await resp.json();

      consent.status = status;

      setConsents(consents.map((c) => (c.id == consent.id ? consent : c)));
      showToast(`Consent request updated`, "success");
    } catch (e) {
      console.log(e);
      showToast(`Unable to update consent request`, "error");
    }
    StopSpinner();
  };

  useEffect(() => {
    getConsents();
  }, []);

  return (
    <>
      <div className="main_title">
        <h1><i class="fa-solid fa-angle-left me-5 mobile-visible-div" onClick={() => { location.href = location.origin + '/user/my-account' }}></i>Photo Sharing Consent</h1>
        {/* {
          rData.photographer_role == false
            ?
            <h4 className="sub_title">  Requests from your photographer to share an image on social media for the sake of promoting their business.</h4>
            :
            <h4 className="sub_title">Requests sent to your client for permission to share an image on social media for the sake of promoting your business.</h4>
        }  */}
      </div>

      {consents.map((consent, index) => {
        return (
          <div key={index}>
            <div className="received_request">
              <div className="col d-flex">
                <div
                  className="received_request_avatar mb-2"
                  onClick={() => {
                    if (consent?.image_id) {
                      var src = consent.image_url;
                      var overlay = document.createElement('div');
                      overlay.style.background = 'rgba(0, 0, 0, 1) url("' + src + '") no-repeat center';
                      overlay.style.backgroundSize = 'contain';
                      overlay.style.width = '100%';
                      overlay.style.height = '100%';
                      overlay.style.position = 'fixed';
                      overlay.style.zIndex = '10000';
                      overlay.style.top = '0';
                      overlay.style.left = '0';
                      overlay.style.cursor = 'zoom-out';
                      overlay.addEventListener('click', function () {
                        document.body.removeChild(overlay);
                      });
                      document.body.appendChild(overlay);
                    }
                  }}
                >
                  {consent.image_type?.includes("image") ? (
                    <img
                      src={consent.image_url}
                      alt={consent.image_name}
                      onError={(e) =>
                        (e.target.src = ASSETS.event_card_image_placeholder)
                      }
                    />
                  ) : (
                    <video
                      controls={false}
                      autoPlay={true}
                      muted={true}
                      poster={ASSETS.event_card_image_placeholder}
                    >
                      <source src={consent.image_url} type="video/mp4" />
                      <img src={ASSETS.event_card_image_placeholder} />
                    </video>
                  )}
                </div>
                <div className="receiver_detail">
                  <ul>
                    <li className="received_request_event_name">
                      {consent.event_name ||
                        consent.image_name ||
                        consent.album_title ||
                        ""}
                    </li>
                    <li className="photographer_name fregular">
                      {consent.requested_by}
                    </li>
                    <li className="photographer_name fregular">
                      {moment(new Date(consent.created_at)).format("MMMM DD, YYYY ").toLocaleString()}
                    </li>
                    <li>

                      {
                        (consent?.status == 'requested' && rData.photographer_role) && <div className="text-yellow">Requested</div>
                      }
                      {
                        consent?.status == 'accepted' && <div className="text-green">Accepted</div>
                      }
                      {
                        consent?.status == 'rejected' && <div className="text-red">Rejected</div>
                      }

                    </li>
                  </ul>
                </div>
              </div>
              {consent?.status == "requested" && consent?.client_id == rData.current_user_id && (
                <div className="request_approver-btn">
                  <div className="action_wrap d-flex">
                    <div className="flex_one me-1">
                      <button
                        type="button"
                        className="btn-w-full btn-gray"
                        onClick={() => updateConsent(consent, "approve")}
                      >
                        <i className="fa-solid fa-check mr-2" />
                        Accept
                      </button>
                    </div>
                    <div className="flex_one ms-1">
                      <button
                        type="button"
                        className="btn-w-full btn-outer-gray"
                        onClick={() => updateConsent(consent, "reject")}
                      >
                        <i className="fa-solid fa-xmark mr-2" />Reject
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>

          </div>

        );
      })}
      {consents.length == 0 && dataStatus == 'loaded' && (
        <div class="shot-list-img no-footer-message"> <div className="d-flex flex-column align-items-center justify-content-center">
          <img src={ASSETS.no_image} className="w-auto no-image my-2" /><p className='font-10'>{rData.photographer_role == false ? "No requests received" : "No requests sent"}</p></div></div>
      )}
      <Modal

        opened={currentConsent}
        onClose={() => setCurrentConsent(null)}
        size="lg"
      >
        {currentConsent?.image_type?.includes("image") ? (
          <>
            <img
              src={currentConsent?.image_url}
              alt={currentConsent?.image_name}
              className="img-fluid"
              onError={(e) =>
                (e.target.src = ASSETS.event_card_image_placeholder)
              }
            />
          </>
        ) : (
          <video
            controls={false}
            autoPlay={true}
            muted={true}
            className="img-fluid"
            poster={ASSETS.event_card_image_placeholder}
          >
            <source src={currentConsent?.image_url} type="video/mp4" />
            <img src={ASSETS.event_card_image_placeholder} />
          </video>
        )}
      </Modal>
    </>
  );
};

export default ConsentActionComponent;
